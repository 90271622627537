import React, { useEffect } from "react";
import {
    HotelCard,
    initMessages,
    Message,
    MessageRole,
    ResponseChatModel,
    ResponseHotelModel,
    ResponseWithLinkModel,
} from "../models/ChatModel";
import { getCookie } from "../utils/utils";

const updateLastItem =
    <T>(msgFn: (message: T) => T) =>
    (currentMessages: T[]) =>
        currentMessages.map((msg, i) => {
            if (currentMessages.length - 1 === i) {
                return msgFn(msg);
            }
            return msg;
        });

export const useChatCompletion = (clientId: string, url: URL) => {
    const [messages, setMessages] = React.useState<Message[]>(initMessages());
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    function isChatResponse(obj: any): obj is ResponseChatModel {
        return (obj as ResponseChatModel).text !== undefined;
    }

    function isHotelResponse(obj: any): obj is ResponseHotelModel {
        return (
            typeof obj === "object" &&
            obj !== null &&
            "tours" in obj &&
            "data" in obj
        );
    }

    function isLinkResponse(obj: any): obj is ResponseWithLinkModel {
        return typeof obj === "object" && obj !== null && "link" in obj;
    }

    function handleHotelResponse(parsedChunk: ResponseHotelModel) {
        const hotels = parsedChunk.tours ?? [];

        if (hotels.length !== 0) {
            hotels.forEach((tour) => {
                tour.adults = parsedChunk.data.adults;
                tour.kids = parsedChunk.data.kids;
            });
            const sortedHotels = hotels.sort((a, b) => {
                const getPopularity = (hotelCard: HotelCard): number => {
                    const { hotel, kids, adults } = hotelCard;

                    if (kids) {
                        return (
                            hotel?.family_popularity ?? hotel?.popularity ?? 0
                        );
                    }

                    if (adults) {
                        const adultsCount =
                            typeof adults === "string"
                                ? parseInt(adults, 10)
                                : adults;

                        if (adultsCount > 1) {
                            return (
                                hotel?.adults_popularity ??
                                hotel?.popularity ??
                                0
                            );
                        } else if (adultsCount === 1) {
                            return (
                                hotel?.single_adult_popularity ??
                                hotel?.popularity ??
                                0
                            );
                        }
                    }

                    return hotel?.popularity ?? 0;
                };

                return getPopularity(b) - getPopularity(a);
            });

            setMessages(
                updateLastItem((msg) => ({
                    id: parsedChunk.id,
                    content: sortedHotels,
                    role: "assistant",
                }))
            );
            if (parsedChunk.message) {
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        role: "assistant",
                        content: parsedChunk.message,
                    } as Message,
                ]);
            }
        }

        const canVibrate = "vibrate" in navigator;
        if (canVibrate) navigator.vibrate(1);
    }

    const submitPrompt = React.useCallback(
        async (newMessage: Message) => {
            setLoading(true);
            setError(null);

            const updatedMessages = [
                ...messages,
                newMessage,
                { role: "assistant", content: "" } as Message,
            ];
            setMessages(updatedMessages);

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        clientId,
                        input: newMessage.content,
                        clientIdFromOT: getCookie("o_client_id") ?? null,
                    }),
                });

                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                const reader = response.body?.getReader();
                const decoder = new TextDecoder();
                let buffer = "";

                if (reader) {
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;

                        const chunk = decoder.decode(value, { stream: true });
                        buffer += chunk;

                        // Process complete SSE events
                        const events = buffer.split(/\n\n/);
                        buffer = events.pop() || ""; // Keep the last incomplete event in the buffer

                        for (const event of events) {
                            const lines = event.split("\n");
                            const dataLines = lines
                                .filter((line) => line.startsWith("data:"))
                                .map((line) => line.slice(5).trim());

                            if (dataLines.length === 0) continue;

                            const jsonStr = dataLines.join("");

                            try {
                                const result = JSON.parse(jsonStr);

                                if (isHotelResponse(result)) {
                                    console.log("it's hotels", result);
                                    handleHotelResponse(result);
                                } else if (isLinkResponse(result)) {
                                    console.log("it's link", result)
                                    if (result.message) {
                                        setMessages((prevMessages) => [
                                            ...prevMessages,
                                            {
                                                role: "assistant",
                                                content: result.message,
                                                buttonLink: result.link,
                                            } as Message,
                                        ]);
                                    }
                                } else if (isChatResponse(result)) {
                                    const contentChunk: string =
                                        result.text ?? "";
                                    setMessages(
                                        updateLastItem((msg) => ({
                                            id: result.id,
                                            content: `${msg.content}${contentChunk}`,
                                            role: `${msg.role}` as MessageRole,
                                        }))
                                    );

                                    const canVibrate = "vibrate" in navigator;
                                    if (canVibrate) navigator.vibrate(1);

                                    if (result.isEnd) break;
                                } else {
                                    console.log(
                                        "it's not chat and hotels, result:",
                                        result
                                    );
                                }
                            } catch (e) {
                                console.error("Failed to parse JSON:", e);
                            }
                        }
                    }
                }

                // Process any remaining data in the buffer
                if (buffer.length > 0) {
                    const dataLines = buffer
                        .split("\n")
                        .filter((line) => line.startsWith("data:"))
                        .map((line) => line.slice(5).trim());

                    if (dataLines.length > 0) {
                        const jsonStr = dataLines.join("");
                        try {
                            const result = JSON.parse(jsonStr);
                            if (isHotelResponse(result)) {
                                handleHotelResponse(result);
                            } else if (isLinkResponse(result)) {
                                if (result.message) {
                                    setMessages((prevMessages) => [
                                        ...prevMessages,
                                        {
                                            role: "assistant",
                                            content: result.message,
                                            buttonLink: result.link,
                                        } as Message,
                                    ]);
                                }
                            }
                        } catch (e) {
                            console.error("Failed to parse remaining JSON:", e);
                        }
                    }
                }
            } catch (err) {
                console.log(err);

                setError(
                    err instanceof Error
                        ? err.message
                        : "An unknown error occurred"
                );

                setMessages(
                    updateLastItem((msg) => ({
                        content: `${msg.content}\n К сожалению, произошла непредвиденная ошибка. Пожалуйста, попробуйте ещё раз или повторите попытку позже.`,
                        role: "assistant",
                    }))
                );
            } finally {
                setLoading(false);
            }
        },
        [clientId, messages, url]
    );

    useEffect(() => {
        const savedMessages = localStorage.getItem(`${clientId}_m`);
        try {
            if (savedMessages) {
                setMessages(JSON.parse(savedMessages));
            }
        } catch (err) {}
    }, [clientId]);

    useEffect(() => {
        if (messages.length > 3 && !loading) {
            localStorage.setItem(`${clientId}_m`, JSON.stringify(messages));
        }
    }, [clientId, messages, loading]);

    return {
        messages,
        loading,
        error,
        submitPrompt,
        setMessages,
    };
};
