import React, { createRef, useEffect } from "react";
import { motion } from "framer-motion";
import { HotelCard } from "../../models/ChatModel";
import HotelLink from "./HotelLink";
import { formatDate, getChildrensText, getUrl } from "../../utils/utils";

interface HotelCardsCarouselProps {
    cards: HotelCard[];
    clientId: string;
}

const HotelCardsCarousel: React.FC<HotelCardsCarouselProps> = ({
    cards,
    clientId,
}) => {
    const cardProps = createRef<HTMLDivElement>();
    const [leftConstrint, setLeftConstraint] = React.useState(
        -window.innerWidth * 0.7 * (cards.length - 1)
    );

    useEffect(() => {
        if (cardProps?.current?.parentElement?.clientWidth !== undefined) {
            setLeftConstraint(
                -cardProps.current.parentElement?.clientWidth *
                    (cards.length - 1)
            );
        }
    }, [cardProps, cards]);

    const formatPriceNumber = (number?: number) => {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    return (
        <div className="relative w-full overflow-hidden overflow-x-visible">
            <motion.div
                className="flex items-stretch"
                drag="x"
                dragConstraints={{
                    right: 0,
                    left: leftConstrint,
                    top: 0,
                    bottom: 0,
                }}
                onDrag={(e) => e.preventDefault()}
            >
                {cards.map((card, index) => (
                    <motion.div key={index} className="flex-none w-[70dvw]">
                        <div className="bg-white rounded-xl shadow-md p-4 m-2 flex flex-col justify-between h-[27rem]">
                            <div>
                                <img
                                    src={card.hotel?.main_photo}
                                    alt={card.hotel?.name}
                                    className="w-full h-40 object-cover rounded-xl mb-2"
                                />
                                <h3 className="text-lg font-extrabold text-[#224824] line-clamp-3">
                                    {card.hotel?.name} {card.hotel?.category}
                                </h3>
                                <div className="grow" />
                            </div>
                            <div>
                                <p className="text-sm text-[#224824]">
                                    Взрослых: {card.adults ?? "Нет данных"},
                                    {getChildrensText(card.kids)}
                                </p>
                                <p className="text-sm text-[#224824]">
                                    Дата заезда:{" "}
                                    {formatDate(card.startDate) ?? "Нет данных"}
                                </p>
                                <p className="text-sm text-[#224824]">
                                    Дней в отеле: {card.duration}
                                </p>

                                <p className="text-lg font-extrabold mt-2 text-[#224824]">
                                    от <span className="ml-2" />{" "}
                                    {formatPriceNumber(card.price)} руб.
                                </p>
                                <HotelLink
                                    clientId={clientId}
                                    tourId={card.tourId}
                                    url={`${getUrl()}/`}
                                />
                            </div>
                        </div>
                        <div
                            className="bg-transparent rounded-lg p-4 m-2 w-[70dvw]"
                            ref={cardProps}
                        />
                    </motion.div>
                ))}
            </motion.div>
        </div>
    );
};

export default HotelCardsCarousel;
